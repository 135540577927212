import { createApi, fetchBaseQuery, } from "@reduxjs/toolkit/query/react";
import moment from "moment";
import { BusinessRole, SubscriptionType, } from "../types/business_config";
export { BusinessRole as BusinessRole };
const receptiveBaseQuery = async (args, api, extraOptions) => {
    const url = process.env.REACT_APP_API_HOST ?? process.env.EXPO_PUBLIC_API_HOST;
    if (!url) {
        return {
            error: {
                status: 500,
                data: "API host not set",
            },
        };
    }
    return fetchBaseQuery({
        baseUrl: `${url}/api`,
        timeout: 5000,
        prepareHeaders: (headers, { getState }) => {
            const state = getState();
            let accessToken = null;
            if (process.env.REACT_APP_API_HOST) {
                // on web
                const webState = state;
                accessToken = webState.account.accessToken;
            }
            else if (process.env.EXPO_PUBLIC_API_HOST) {
                // on mobile
                const mobileState = state;
                accessToken = mobileState.auth.token?.access_token;
            }
            if (accessToken) {
                headers.set("Authorization", `Bearer ${accessToken}`);
            }
        },
    })(args, api, extraOptions);
};
export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: receptiveBaseQuery,
    tagTypes: [
        "CallIsUnread",
        "CallIsTrash",
        "TrialBotConfig",
        "PhoneBotConfig",
        "PhoneNumbers",
        "BusinessConfig",
        "UserBusinessConfig",
        "BusinessStats",
        "CallSummaries",
        "CallSummaryFollowUpCount",
        "Chats",
        "Calendar",
        "Event",
    ],
    endpoints: (builder) => ({
        simpleLogin: builder.query({
            query: ({ username, password }) => ({
                url: "/users/token_simple",
                method: "POST",
                body: { username, password },
            }),
        }),
        smsVerifyLogin: builder.query({
            query: ({ phoneNumber, code }) => ({
                url: "/users/token_sms",
                method: "POST",
                params: {
                    phone_number: phoneNumber,
                    code,
                },
            }),
        }),
        googleCreatePin: builder.query({
            query: (credential) => ({
                url: "/users/google/create_pin",
                method: "POST",
                body: credential,
            }),
        }),
        appleCreatePin: builder.query({
            query: (credential) => ({
                url: "/users/apple/create_pin",
                method: "POST",
                body: credential,
            }),
        }),
        verificationCreate: builder.query({
            query: (phoneNumber) => ({
                url: "/leads/verification_create",
                method: "POST",
                body: { phone_number: phoneNumber },
            }),
        }),
        verificationCheck: builder.query({
            query: ({ phoneNumber, code }) => ({
                url: "/leads/verification_check",
                method: "POST",
                body: { phone_number: phoneNumber, code },
            }),
        }),
        getUserBusinessConfig: builder.query({
            query: ({ businessId, userId }) => "/businesses/" + businessId + "/" + userId + "/config",
            providesTags: (_result, _error, { businessId, userId }) => [
                { type: "UserBusinessConfig", id: `${businessId}-${userId}` },
            ],
        }),
        getBusinessConfig: builder.query({
            query: (businessId) => "/businesses/" + businessId + "/config",
            providesTags: (_result, _error, businessId) => [
                { type: "BusinessConfig", id: businessId },
            ],
        }),
        getPhoneNumbersForBusiness: builder.query({
            query: (businessId) => "/phones/business/" + businessId,
            transformResponse: (response) => response.map((p) => p.number),
            providesTags: (_result, _error, businessId) => [
                { type: "PhoneNumbers", id: businessId },
            ],
        }),
        getBotConfig: builder.query({
            async queryFn({ businessId, phoneNumber }, _api, _extraOptions, fetchWithBQ) {
                if (phoneNumber) {
                    const response = await fetchWithBQ({ url: "/phones/" + phoneNumber });
                    if (response.error) {
                        return { error: response.error };
                    }
                    const phone = response.data;
                    return { data: phone.bot_config };
                }
                else {
                    const response = await fetchWithBQ({
                        url: "/businesses/" + businessId + "/config",
                    });
                    if (response.error) {
                        return { error: response.error };
                    }
                    const businessConfig = response.data;
                    if (businessConfig.subscription?.subscription_type !==
                        SubscriptionType.TrialPin &&
                        businessConfig.subscription?.subscription_type !==
                            SubscriptionType.Trial) {
                        return { data: null };
                    }
                    else {
                        return { data: businessConfig.subscription?.bot_config };
                    }
                }
            },
            providesTags: (_result, _error, { businessId, phoneNumber }) => [
                { type: "TrialBotConfig", id: businessId },
                { type: "PhoneBotConfig", id: phoneNumber ?? "undefined" },
            ],
        }),
        getBusinessStats: builder.query({
            query: (businessId) => "/stats/" + businessId + "/call_sms",
            providesTags(_result, _error, arg) {
                return [{ type: "BusinessStats", id: arg }];
            },
        }),
        getCallSummaries: builder.query({
            query: ({ businessId, followUpOnly, unreadOnly, trashOnly, offset, limit, }) => `/calls/summary/${businessId}?limit=${limit}&offset=${offset}&follow_up_only=${followUpOnly}&unread_only=${unreadOnly}&trash_only=${trashOnly}`,
            providesTags: (_result, _error, { businessId, followUpOnly, unreadOnly, trashOnly, offset, limit }) => [
                {
                    type: "CallSummaries",
                    id: `${businessId}-${followUpOnly}-${unreadOnly}-${trashOnly}-${offset}-${limit}`,
                },
                { type: "CallSummaries" },
            ],
        }),
        getChats: builder.query({
            query: ({ businessId, offset, limit }) => `/chats/business/${businessId}?limit=${limit}&offset=${offset}`,
            providesTags: (_result, _error, { businessId }) => [
                { type: "Chats", id: businessId },
            ],
        }),
        getChatsWithNumber: builder.query({
            query: ({ businessId, phoneNumber, offset, limit }) => `/chats/business/${businessId}/customer/${phoneNumber}?limit=${limit}&offset=${offset}`,
            providesTags: (_result, _error, { businessId, phoneNumber }) => [
                { type: "Chats", id: `${businessId}-${phoneNumber}` },
            ],
        }),
        getCallSummaryFollowUpCount: builder.query({
            query: (businessId) => `/calls/summary/${businessId}/count`,
            providesTags: (_result, _error, businessId) => [
                { type: "CallSummaryFollowUpCount", id: businessId },
            ],
        }),
        getDefaultCalendarForBusiness: builder.query({
            query: (businessId) => `/calendar/business/${businessId}/default`,
            providesTags: (_result, _error, businessId) => [
                { type: "Calendar", id: businessId },
            ],
        }),
        getDayEventsForCalendar: builder.query({
            query: ({ calendarId, day }) => ({
                url: `/calendar/events/${calendarId}`,
                params: {
                    before: moment(day).endOf("day").toISOString(),
                    after: moment(day).startOf("day").toISOString(),
                },
            }),
            providesTags: (_result, _error, { calendarId, day }) => [
                { type: "Event", id: `LIST-CAL-${calendarId}-${day}` },
            ],
        }),
        getEventById: builder.query({
            query: (eventId) => `/calendar/event/${eventId}`,
            providesTags: (_result, _error, eventId) => [
                { type: "Event", id: eventId },
            ],
        }),
        getCallIsUnread: builder.query({
            query: (callId) => `/calls/${callId}/is_unread`,
            providesTags: (_result, _error, callId) => [
                { type: "CallIsUnread", id: callId },
            ],
        }),
        getCallIsTrash: builder.query({
            query: (callId) => `/calls/${callId}/is_trash`,
            providesTags: (_result, _error, callId) => [
                { type: "CallIsTrash", id: callId },
            ],
        }),
        getPhoneNumbersToPurchase: builder.query({
            query: ({ areaCode, country, limit }) => ({
                url: "/leads/number_search",
                params: { area_code: areaCode, country, limit },
            }),
        }),
        getStripeSessionDetails: builder.query({
            query: (sessionId) => `/payments/session/${sessionId}`,
        }),
        updateUserPhone: builder.mutation({
            query: ({ userId, phoneNumber }) => ({
                url: "/users/" + userId + "/phone",
                method: "PUT",
                params: { phone: phoneNumber },
            }),
        }),
        updateBotConfig: builder.mutation({
            async queryFn({ businessId, botConfig, phoneNumber }, _api, _extraOptions, fetchWithBQ) {
                if (phoneNumber) {
                    const response = await fetchWithBQ({
                        url: "/phones/" + phoneNumber + "/config",
                        method: "PUT",
                        body: botConfig,
                    });
                    if (response.error) {
                        return { error: response.error };
                    }
                    return { data: response.data };
                }
                else {
                    const response = await fetchWithBQ({
                        url: "/businesses/" + businessId + "/trial_bot_config",
                        method: "PUT",
                        body: { bot_config: botConfig },
                    });
                    if (response.error) {
                        return { error: response.error };
                    }
                    return { data: response.data };
                }
            },
            invalidatesTags: (result, error, { businessId, phoneNumber }) => [
                { type: "TrialBotConfig", id: businessId },
                { type: "BusinessConfig", id: businessId },
                { type: "PhoneBotConfig", id: phoneNumber ?? "undefined" },
            ],
        }),
        sendMessage: builder.mutation({
            query: ({ businessId, phoneNumber, message }) => ({
                url: "/chats/telnyx/business/" + businessId,
                method: "POST",
                body: {
                    customer_phone: phoneNumber,
                    text: message,
                },
            }),
            invalidatesTags: (result, error, { businessId, phoneNumber }) => [
                { type: "Chats", id: `${businessId}-${phoneNumber}` },
                { type: "Chats", id: businessId },
            ],
        }),
        markCallAsRead: builder.mutation({
            query: (callId) => ({
                url: `/calls/${callId}/mark_read`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, callId) => [
                { type: "CallIsUnread", id: callId },
            ],
        }),
        markCallAsUnread: builder.mutation({
            query: (callId) => ({
                url: `/calls/${callId}/mark_unread`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, callId) => [
                { type: "CallIsUnread", id: callId },
            ],
        }),
        markCallAsTrash: builder.mutation({
            query: (callId) => ({
                url: `/calls/${callId}/mark_trash`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, callId) => [
                { type: "CallIsTrash", id: callId },
                { type: "CallSummaries" },
            ],
        }),
        markCallAsNotTrash: builder.mutation({
            query: (callId) => ({
                url: `/calls/${callId}/mark_not_trash`,
                method: "PUT",
            }),
            invalidatesTags: (result, error, callId) => [
                { type: "CallIsTrash", id: callId },
                { type: "CallSummaries" },
            ],
        }),
        checkout: builder.mutation({
            query: (request) => ({
                url: "/payments/checkout",
                method: "POST",
                body: request,
            }),
        }),
        purchasePhone: builder.mutation({
            query: ({ request, businessId }) => ({
                url: `/phones/purchase_phone/${businessId}`,
                method: "POST",
                body: request,
            }),
            invalidatesTags: (result, error, { businessId }) => [
                { type: "PhoneNumbers", id: businessId },
            ],
        }),
        updateAppLead: builder.mutation({
            query: (lead) => ({
                url: "/leads/",
                method: "POST",
                body: lead,
            }),
        }),
        scribe: builder.mutation({
            query: (evt) => ({
                url: "/scribe/",
                method: "POST",
                body: evt,
            }),
        }),
    }),
});
export const { useSimpleLoginQuery, useLazySimpleLoginQuery, useLazySmsVerifyLoginQuery, useLazyGoogleCreatePinQuery, useLazyAppleCreatePinQuery, useLazyVerificationCreateQuery, useLazyVerificationCheckQuery, useLazyGetPhoneNumbersToPurchaseQuery, useGetBusinessConfigQuery, useLazyGetBusinessConfigQuery, useGetBusinessStatsQuery, useGetCallSummariesQuery, useGetCallSummaryFollowUpCountQuery, useGetChatsQuery, useGetChatsWithNumberQuery, useGetPhoneNumbersForBusinessQuery, useLazyGetPhoneNumbersForBusinessQuery, useGetBotConfigQuery, useGetUserBusinessConfigQuery, useGetDefaultCalendarForBusinessQuery, useGetDayEventsForCalendarQuery, useGetEventByIdQuery, useGetCallIsUnreadQuery, useGetCallIsTrashQuery, useLazyGetStripeSessionDetailsQuery, useSendMessageMutation, useUpdateUserPhoneMutation, useUpdateBotConfigMutation, useMarkCallAsReadMutation, useMarkCallAsUnreadMutation, useMarkCallAsTrashMutation, useMarkCallAsNotTrashMutation, useCheckoutMutation, usePurchasePhoneMutation, useUpdateAppLeadMutation, useScribeMutation, } = apiSlice;
